@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Dongle&family=Mulish:wght@200&family=Nunito:wght@200&family=Rubik:wght@300&family=Zen+Kurenaido&display=swap");

/* Default for desktop */
body {
  background-image: url("https://i.postimg.cc/6p3Pg2rY/jujutsu-kaisen.jpg");
  background-size: cover;
  background-attachment: fixed;
  font-family: Nunito, sans-serif;
}

/* Mobile-specific styles using media queries */
@media (max-width: 768px) {
  body {
    background-image: url("https://i.postimg.cc/HxkVrhMM/1012681.png");
  }
}
